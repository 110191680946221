// 汇川socket
import monitor from "@/utils/api/monitor";
import DataParsing from "../websocket/dataparsing.js";
import "../websocket/base64.js";
import "../websocket/sellon_static.js";
var global_callback = null; // 回调函数
var socket = null;
var tRDWSocket = null;
var dataParsing = new DataParsing();
var elevators = ""; // 接受的电梯数据
var redressStatus = {};
var wsObject = {};
var F5Data = [];
var flag = ""; // 判断是哪种解析数据 端子状态等
var tryTime = 0;
var sid = "";
const url = "wss://greatdt.dataserver.cn/monitor/websocket";
export default {
  sendSock(agentData, callback, f) {
    console.log("汇川");
    // agentData=>socket所需参数 callback=>回调函数
    elevators = agentData;
    global_callback = callback;
    this.setRedressStatus();
    monitor.eleSid().then((res) => {
      sid = res.data;
      this.initSocket();
    });
    flag = f || "";
  },
  closeSocket() {
    elevators = [];
    global_callback = null; // 回调函数
    dataParsing = new DataParsing();
    redressStatus = {};
    wsObject = {};
    F5Data = [];
    flag = ""; // 判断是哪种解析数据 端子状态等
    tryTime = 0;
    sid = "";
    console.log("closeSocket");
    if (socket && socket.readyState === 1) {
      console.log("socket");
      socket.close();
      socket = null;
    }
    if (tRDWSocket && tRDWSocket.readyState === 1) {
      console.log("tRDWSocket");
      tRDWSocket.close();
      tRDWSocket = null;
    }
  },
  initSocket() {
    var that = this;
    socket = new WebSocket(url);
    socket.onopen = function () {
      that.sendMessageToAPPServer();
    };
    socket.onmessage = function (e) {
      var data = e.data;
      data = typeof data == "string" ? that.stringToJSON(data) : data;
      if (data.errorCode == "noSession") {
        that.socketError();
      } else {
        if (data.msgType == "GET_TD_REALTIME_DATA_RSP" && data.errorCode == 0) {
          // 实时数据请求响应
          that.buildRdtsWebSocket(data, false);
        } else if (data.msgType == "GET_SINGLE_DATA") {
          //实时数据信号强度
          elevators.map((ele) => {
            if (ele.intelHardwareNumber === data.regCode) {
              that.dealWithRealSignal(data, ele);
            }
          });
        }
      }
    };
    socket.onerror = function (e) {
      that.socketError();
    };
    socket.onclose = function () {
      that.socketError();
    };
  },
  dealWithRealSignal(data, ele) {
    //处理实时信号
    var signalType = data.signalType;
    var signalValue = data.signalValue;
    var signalNum = this.dealWithSignalStrength(signalType, signalValue);
    ele.signalIntensity =
      signalType == 4
        ? "icon_ethernet"
        : signalType == 3
        ? "icon_swifi_" + signalNum
        : "icon_mobile_" + signalNum;
  },
  dealWithSignalStrength(signalType, signalValue) {
    //处理信号强度
    if (signalType == 4) {
      return 0;
    }
    var v =
      signalType == 3
        ? Math.round(((signalValue + 94) / 94) * 100)
        : Math.round((signalValue / 31) * 100);
    var gridNum = signalType == 3 ? 4 : 5,
      fullNum = 100;
    if (v <= 0) {
      return 0;
    } else if (v <= (fullNum / gridNum) * 1) {
      return 1;
    } else if (v <= (fullNum / gridNum) * 2 && v > (fullNum / gridNum) * 1) {
      return 2;
    } else if (v <= (fullNum / gridNum) * 3 && v > (fullNum / gridNum) * 2) {
      return 3;
    } else if (v <= (fullNum / gridNum) * 4 && v > (fullNum / gridNum) * 3) {
      return 4;
    } else if (
      v <= (fullNum / gridNum) * 5 &&
      v > (fullNum / gridNum) * 4 &&
      signalType != 3
    ) {
      return 5;
    }
    return 0;
  },
  socketError() {
    // 重连
    tryTime++;
    if (tryTime < 3) {
      socket = null;
      this.initSocket();
    }
  },
  sendMessage(data) {
    var postData = {
      from: "evdhs_003@ece",
      proxy: "",
      to: "gdhs_002@inovance",
      version: "1.0",
      msgType: "GET_TD_REALTIME_DATA_REQ",
      deviceSerial: elevators[0].intelHardwareNumber,
      tdSerial: elevators[0].tdSerial,
      sid: sid,
      tdDataTags: data,
    };
    if (socket && socket.readyState == 1) {
      socket.send(JSON.stringify(postData));
    } else {
      setTimeout(() => {
        this.sendMessage(data);
      }, 100);
    }
  },
  sendMessageToAPPServer() {
    var postData2 = {
      sid: sid,
      msgType: "FLOATING_WINDOW",
    };
    if (socket && socket.readyState == 1) {
      socket.send(JSON.stringify(postData2));
    }
    let mulElevator = JSON.parse(JSON.stringify(elevators));
    if (mulElevator.length > 1) {
      // 首台电梯获取不到状态的bug
      mulElevator.splice(0, 0, mulElevator[0]);
    }
    mulElevator.map((ele) => {
      var postData = {
        from: "evdhs_003@ece",
        proxy: "",
        to: "gdhs_002@inovance",
        version: "1.0",
        msgType: "GET_TD_REALTIME_DATA_REQ",
        deviceSerial: ele.intelHardwareNumber,
        tdSerial: "1",
        sid: sid,
        tdDataTags: [
          { value: 1, samplingPeriod: 1000 },
          { value: 2, samplingPeriod: 1000 },
          { value: 3, samplingPeriod: 1000 },
          { value: 5, samplingPeriod: 1000 },
          // { value: 4, samplingPeriod: 1000 },
          // { value: 15, samplingPeriod: 65535 },
        ],
      };
      if (socket && socket.readyState == 1) {
        socket.send(JSON.stringify(postData));
      }
      this.querySignalByData(ele);
    });
    this.sendHeartBeatPackage(socket, 1, null);
  },
  querySignalByData(ele) {
    //查询信号
    var postData = {
      deviceSerial: ele.intelHardwareNumber,
      msgType: "DEVICE_DATA_TRANSFER",
      tdSerial: "",
      transferData: "",
      sid: sid,
      transferMsgType: 51,
    };
    if (socket && socket.readyState == 1) {
      socket.send(JSON.stringify(postData));
    }
    return setTimeout(() => {
      this.querySignalByData(ele);
    }, 3000000);
  },
  sendHeartBeatPackage(ws, type, data) {
    // var webSoc = type == 1 ? socket : tRDWSocket;
    // var fun = arguments.callee();
    // console.log(Date(),ws)
    if (ws === null) {
      return;
    }
    var params = {
      from: "evdhs_003@ece",
      proxy: "",
      to: "gdhs_002@inovance",
      version: "1.0",
      msgType: "SERVER_HEARTBEAT_REQ",
      sid: sid,
    };
    if (ws.readyState == 1) {
      ws.send(JSON.stringify(params));
    }
    if (type == 2 && (ws.readyState == 2 || ws.readyState == 3)) {
      ws.close();
      // this.buildRdtsWebSocket(data, true);
    }
    return setTimeout(() => {
      if (ws) {
        this.sendHeartBeatPackage(ws, type, data);
      }
    }, 25000);
  },
  buildRdtsWebSocket(data, rebuild) {
    var wsURL =
      "wss://rdts.dataserver.cn/rdts?ip=" +
      data.rdtsHost +
      "&port=" +
      data.rdtsWsPort;
    var that = this;
    if (wsObject[wsURL] && !rebuild) {
      this.sendMessageToRDTS(data, wsObject[wsURL]);
      return;
    }
    tRDWSocket = wsObject[wsURL] = new WebSocket(wsURL);
    tRDWSocket.onopen = function () {
      
      if (!rebuild) {
        that.sendHeartBeatPackage(tRDWSocket, 2, data);
      }
      //发送数据
      that.sendMessageToRDTS(data, tRDWSocket);
    };
    tRDWSocket.onmessage = function (e) {
      var data = e.data;
      data = typeof data == "string" ? that.stringToJSON(data) : data;
      if (data.msgType === "DEVICE_DATA_TRANSFER") {
        //实时数据透传数据
        elevators.map((ele) => {
          if (ele.intelHardwareNumber === data.deviceSerial) {
            var tagValue = data.tdDataTags * 1;
            var tdId = that.getTdId(ele, 0);
            dataParsing.addData({
              id: tdId,
              dataSource: data,
              ctrlType: ele.controllerType,
            });
            that.fillRealTimeData(tagValue, tdId, ele);
          }
        });
        if (global_callback) {
          flag === "" ? global_callback(elevators) : global_callback(F5Data);
        }
      }
    };
    tRDWSocket.onerror = function () {
      tRDWSocket.close();
    };
    tRDWSocket.onclose = function () {
      tRDWSocket.close();
    };
  },
  sendMessageToRDTS(data, rdtsWS) {
    var params = {
      from: data.from,
      proxy: "",
      to: "gdhs_002@inovance",
      version: "1.0",
      securityCode: data.securityCode,
      msgType: "TD_REALTIME_DATA_ESTABLISH_REQ",
      sid: sid,
    };
    if (rdtsWS) {
      if (rdtsWS.readyState == 1) {
        rdtsWS.send(JSON.stringify(params));
      } else if (rdtsWS.readyState != 4) {
        setTimeout(() => {
          this.sendMessageToRDTS(data, rdtsWS);
        }, 600);
      }
    }
  },
  stringToJSON(data) {
    /* 字符串转JSON对象 */
    try {
      data = JSON.parse(data);
    } catch (e) {
      data = eval("(" + data + ")");
    }
    return data;
  },
  getTdId(data, type) {
    return (
      data.intelHardwareNumber + data.tdSerial + (data.type ? data.type : type)
    );
  },
  fillRealTimeData(tagValue, tdId, ele) {
    ele.flagType = "huichuan"
    //实时数据
    var regCode = ele.intelHardwareNumber;
    var realTimeData = dataParsing.getFirstData(tdId, tagValue);
    // console.log(realTimeData);
    // console.log(tagValue);
    if (!realTimeData) {
      return;
    } else if (flag === "tag") {
      F5Data = realTimeData;
      return;
    }
    if (tagValue === 1) {
      realTimeData.map((item) => {
        if (item.name == "串口通信异常") {
          if (item.value == "故障") {
            this.setFloorFault(regCode, 0, ele);
          }
        } else if (item.name == "运行方向") {
          this.setDirection(item.escapeValue, regCode, ele);
        } else if (item.name == "楼层") {
          this.setFloor(item.escapeValue, regCode, ele);
        } else if (item.name == "开关门状态") {
          this.setDoorStatus(item.escapeValue, regCode, ele);
        }else if (item.name == "检修状态") {
          
          this.setEleStatus(item.escapeValue, item.name, ele);
        }else if (item.name == "故障状态") {
          this.setEleStatus(item.escapeValue, item.name, ele);
        }else if (item.name == "负载状态") {
          // console.log(item)
          this.setEleStatus(item.escapeValue, item.name, ele);
        }

      });
    } else if (tagValue === 2) {
      /* 内召数据 */
      var innerData = dataParsing.getInnerData(realTimeData);
      this.dealTagData(innerData, "callInCar", ele);
    } else if (tagValue == 3) {
      /* 外召数据 */
      var outerData = dataParsing.getOuterData(realTimeData);
      var callUp = outerData ? outerData.callUp : null;
      var callDown = outerData ? outerData.callDown : null;
      this.dealTagData(callUp, "upCall", ele);
      this.dealTagData(callDown, "downCall", ele);
    } else if (tagValue == 5) {
      //电梯运行时间，运行次数
      ele.sysRuntime = realTimeData[0].escapeValue / 60;
      ele.runCount = realTimeData[1].escapeValue;
    } else if (tagValue == 15) {
      // f5功能参数
      if (flag === "F5") {
        F5Data[0] = realTimeData;
      }
    } else if (tagValue == 4) {
      // 端子参数
      if (flag === "F5") {
        F5Data[1] = realTimeData;
      }
    }
  },
  setFloorFault(regCode, type, ele) {
    //串口通信异常时，不显示楼层信息，显示“通讯异常”
    if (type == 0) {
      //故障
      ele.floor = "通讯异常";
    }
  },
  setDirection(value, regCode, ele) {
    ele.flagType = "huichuan"
    //电梯行驶方向
    // 10 上行  01  下行
    ele.flow = value;
  },
  setFloor(value, regCode, ele) {
    ele.flagType = "huichuan"
    //楼层
    if (value == null || value > 160) {
      return;
    }
    var list = ele.floorsList;
    if (list) {
      var flag = false;
      list.map((item) => {
        if (item[1] == value) {
          ele.floor = item[0];
          flag = true;
        }
      });
      if (!flag) {
        ele.floor = value;
      }
    } else {
      ele.floor = value;
    }
    return false;
  },
  setDoorStatus(value, regCode, ele) {
    ele.flagType = "huichuan"
    //开关门状态
    if (value == "00") {
      ele.runImg = "elevator_big_close.png";
    } else if (value == "10") {
      ele.runImg = "elevator_big_closing.gif";
    } else if (value == "11") {
      ele.runImg = "elevator_big_open.png";
    } else if (value == "01") {
      ele.runImg = "elevator_big_opening.gif";
    }
    ele.runStatus = value; // 待定wyn
  },
  setEleStatus(value, regCode, ele){
    if( regCode == "负载状态") {
      ele.overload = value;
    }
    if( regCode == "故障状态") {
      ele.fault = value;
    }
    if( regCode == "检修状态") {
      ele.check = value;
    }
  },
  setRedressStatus() {
    //设置电梯的状态
    elevators.map((ele) => {
      var tdId = this.getTdId(ele, 0);
      if (redressStatus[tdId]) {
        return;
      }
      redressStatus[tdId] = {
        upkeep: 0,
        chuankou: 0,
        failureCode: 0,
      };
    });
  },
  dealTagData(data, key, ele) {
    ele.flagType = "huichuan"
    // 处理上召、下召、内召数据
    ele[key] = [];
    if (data && data.length > 0) {
      var tempData = [];
      data.map((item) => {
        if (ele.floorsList) {
          var floorList = ele.floorsList || null; //楼层对照表
          if (!floorList) {
            tempData.push(item);
          } else {
            var len = floorList.length;
            floorList.map((obj, idx) => {
              if (idx != len - 1 && obj[1] == item && obj[0] != "") {
                //真实楼层相同，显示楼层非空
                tempData.push(obj[0]); //添加显示楼层
                return false; //跳出each 循环
              }
            });
          }
        } else {
          tempData.push(item);
        }
      });
      ele[key] = Array.from(new Set(tempData));
    }
  },
};
